import styled from 'utils/emotion';

import Stripe from 'components/common/Stripe';
import Text from 'components/common/Text';
import HeadlineGold from 'components/common/HeadlineGold';
import Button from 'components/common/Button';

import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

export const TextTeaserStyled = styled('div')({
  height: 'auto',
});

export const TextTeaserContentTextStyled = styled('div')(
  {
    margin: '0 auto',
  },
  (props) => ({
    textAlign: props.align,
  }),
);

export const TextTeaserTaglineStyled = styled(Text)({});

export const TextTeaserTextStyled = styled(Text)(({ textMaxWidth }) => ({
  maxWidth: textMaxWidth,
}));

export const TextTeaserTextMoreStyled = styled(Text)(
  ({ textMaxWidth, displayToggle }) => ({
    maxWidth: textMaxWidth,
    height: displayToggle ? 'auto' : 0,
    opacity: displayToggle ? 1 : 0,
    transition: '0.4s opacity linear',
    '> *': {
      display: displayToggle ? 'block' : 'none',
    },
  }),
);

export const TextTeaserStripeStyled = styled(Stripe)({
  marginTop: 0,
});

export const TextTeaserHeadlineLargeStyled = styled(HeadlineGold)(
  {
    fontSize: toPx(45),
    lineHeight: toPx(48),
    hyphens: 'auto',

    [media(738, 'desktop')]: {
      fontSize: toPx(64),
      lineHeight: toPx(64),
      letterSpacing: toPx(1),
    },

    [media('tabletFluid')]: {
      hyphens: 'manual',
    },

    [media('desktop')]: {
      fontSize: toPx(95),
      lineHeight: toPx(85),
      letterSpacing: toPx(2),
    },
  },
  (props) => ({
    marginBottom: props.theme.space,

    [media('tabletFluid')]: {
      marginBottom: props.hasBadges
        ? props.theme.spaceQuad - 80
        : props.theme.spaceQuad,
    },
  }),
);

export const TextTeaserHeadlineNormalStyled = styled(HeadlineGold)(
  {
    fontSize: toPx(44),
    lineHeight: toPx(40),
    hyphens: 'auto',

    [media('tablet')]: {
      fontSize: toPx(64),
      lineHeight: toPx(64),
      letterSpacing: toPx(-1),
    },

    [media('tabletFluid')]: {
      hyphens: 'manual',
    },

    'strong, b': {
      letterSpacing: toPx(-1),
    },
  },
  (props) => ({
    marginBottom: props.theme.space,
    [media('tabletFluid')]: {
      marginBottom: props.theme.spaceDoubleThirds,
    },
  }),
);

export const TextTeaserButtonWrapStyled = styled('div')(
  {
    [media('tabletFluid')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  (props) => ({
    marginBottom: props.theme.space,
  }),
);

export const TextTeaserButtonStyled = styled(Button)(
  {
    marginBottom: 0,
  },
  (props) => ({
    marginTop: props.theme.space,

    [media('tabletFluid')]: {
      marginTop: props.theme.spaceDouble,
    },

    ':first-of-type': {
      marginRight: props.theme.spaceHalf,
    },
  }),
);

export const TextTeaserButtonReadmoreStyled = styled(Button)((props) => ({
  marginTop: props.theme.space,
  marginBottom: props.theme.spaceHalf,

  [media('tabletFluid')]: {
    marginTop: props.theme.spaceDouble,
    marginBottom: props.theme.space,
  },
}));

export const BadgeImg = styled('img')((props) => ({
  marginRight: props.theme.spaceHalf,
  height: 50,

  [media('tabletFluid')]: {
    height: 80,
  },
}));
